<template>
	<div>
		<p class="m-right-title">所有错题</p>
		<div class="m-con">
			<p class="u-m-b-20 m-font-type">所属科目：{{ info.course_title}}</p>
			<p class="">
			<span class="m-title-label" v-if="info.type == 1">【单选题】</span>
			<span class="m-title-label" v-if="info.type == 2">【判断题】</span> 
			<span class="m-title-label" v-if="info.type == 3">【多选题】</span>({{page}}/{{count}}) {{info.title}}</p>
			<div class="u-m-t-30">
				<div class="u-m-t-10" v-for="(item,index) in info.options" :key="key">
					<span>{{item.key}} . {{item.value}}</span>
				</div>
			</div>
			
			<p class="u-m-t-50">
				<span class="m-font1">【您的答案】</span>
				<span class="m-font2 u-m-l-4">{{answer}}</span>
			</p>
			<p class="u-m-t-20">
				<span class="m-font1">【正确答案】</span>
				<span class="m-font1 u-m-l-4">{{formatArr(info.answer)}}</span>
			</p>
			<p class="u-m-t-20">
				<span class="m-font1">【答案解析】</span>
			</p>
			<div class="u-m-t-5 u-m-l-10 m-font1"  style="line-height: 24px;" v-html="info.analysis"></div>
			<div class="u-flex u-row-between u-m-t-60">
				<div class="u-flex">
					<span class="m-btn" @click="beforeQuestion">上一题</span>
					<span class="m-btn u-m-l-16" @click="nextQuestion">下一题</span>
				</div>
				<div class="u-flex">
					<!-- <span class="m-btn2">答案</span> -->
					<span class="m-btn2 u-m-l-16" v-if="info.type" @click="collect">取消</span>
				</div>
			</div>
			
			<!-- <div class="m-answer u-m-t-80">
				<div class="m-answer-list">
					<span class="m-answer-item" v-for="(item,index) in 80">{{index + 1}}</span>
				</div>
				<div class="u-flex u-row-center u-col-center">
					<span class="m-btn3">交卷</span>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {to_link} from "@/router/api"
	import {concalFailFun,wrongQuestions} from "@/api/center.js"
	export default {
		data(){
			return {
				loading:false,
				params:{
					order:2,
					id:''
				},
				id:'',
				info:{},
				answer:'',
				page:0,
				count:0,
				isDel:false,
			}
		},
		mounted() {
			console.log(this.$route);
			this.getData()
		},
		methods:{
			to_link,
			collect() {
				concalFailFun({
					id: this.id
				}).then(res => {
					if (res.code == 200) {
						this.isDel = true
						this.params.order = 2
						this.nextQuestion()
						this.$message.success(res.message)
					} else {
						this.$message.error(res.message)
					}
				})
			},
			formatArr(data){
				if(Object.prototype.toString.call(data).slice(8, -1) == 'Array'){
					return data.join(',')
				}else{
					return data
				}
			},
			beforeQuestion(){
				this.params.order = 1
				this.getData()
			},
			nextQuestion(){
				this.params.order = 2
				this.getData()
			},
			async getData(){
				if((this.page == this.count && this.params.order == 2 && this.page !== 0) || (this.page == 1 && this.params.order == 1  && this.page !== 0)) return false;
				this.loading = true
				let res = await wrongQuestions(this.params)
				if(res.code == 200){
					setTimeout(()=>{
						this.loading = false
					},500)
					if(res.code == 200){
						this.count = res.count || 0
						this.answer = res.data.answer
						this.id = res.data.id
						// this.params.id = res.data.id
						this.info = res.data.questions
						
						if(this.isDel) {
							this.isDel = false
							if(this.params.order == 2){
								this.params.id = res.data.nextWrongId
							}else{
								this.params.id = res.data.prevWrongId
							}
							return false
						};
						
						if(this.params.order == 3){
							this.page += 1
						}
						
						if(this.params.order == 2){
							if(this.page < this.count ) {
								this.page += 1
							}
							this.params.id = res.data.nextWrongId
						}else{
							if(this.page > 1) this.page -= 1;
							this.params.id = res.data.prevWrongId
						}
						
						//当前没有数据
						if(this.info == ''){
							this.getData()
							return false;
						}

					}
				}else if(res.code == 0){
					this.info = {}
					this.$message.error(res.message)
					this.$router.back()
				}else{
					this.info = {}
					this.$message.error(res.message)
				}
			}
		}
	}
</script>

<style lang="scss" scoped> 
	.m-font1{
		font-size: 14px;
		color: #474747;
		line-height: 14px;
	}
	.m-font2{
		font-size: 14px;
		color: #F8A310;
		line-height: 14px;
	}
	.m-font-type{
		color: #42ACDC;
		font-size: 16px;
	}
	.m-title-label{
		color: red; 
	}
	.m-right-title{
		display: flex;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}
	
	.m-con{
		margin: 70px 44px;
		.m-con-font1{
			font-size: 14px;
			color: #474747;
			line-height: 22px;
		}
		.m-btn{
			width: 110px;
			height: 33px;
			background: #FFFFFF;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
		}
		.m-btn2{
			width: 87px;
			height: 33px;
			background: #E2E2E2;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
		}
		
	}
		.m-answer{
			display: flex;
			width: 830px;
			padding: 24px 0 0 24px;
			border: 1px solid #D2D2D2;
			.m-btn3{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 36px;
				height: 100px;
				background: #FF8D31;
				border-radius: 3px 3px 3px 3px;
				writing-mode: vertical-lr;
				writing-mode: tb-lr;
				letter-spacing:10px;
				color: #fff;
				font-size: 16px;
				cursor: pointer;
			}
			.m-answer-list{
				display: flex;
				flex-wrap: wrap;
				width: 800px;
				.m-answer-item{
					width: 33px;
					height: 33px;
					background: #F1F1F1;
					border: 1px solid #D2D2D2;
					border-radius: 3px;
					font-size: 15px;
					color: #D6102A;
					text-align: center;
					line-height: 33px;
					cursor: pointer;
					margin-right: 6px;
					margin-bottom: 10px;
				}
			}
		}
	
</style>
<style lang="scss">
	.m-con{
		.el-radio, .el-radio__input{
			white-space:pre-wrap!important;
		}
		.el-checkbox__input.is-checked+.el-checkbox__label{
			color: #F8A310;
		}
		.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #F8A310;
			border-color: #F8A310;
		}
	}
</style>

